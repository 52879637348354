h3 {
  color: #043899;
}

#wm-root {
  height: 100vh;
}

#wm-header {
  background-color: #e7effe;
}

#wm-home-tagline {
  position: absolute;
  top: 0;
  text-align: center;
  padding: 24px;
  color: #ccc;
  font-size: 2em;
  font-style: italic;
}

.page-container {
  margin-top: 12px;
}

.project-header {
  margin-top: 12px;
  padding: 4px;
  background-color: #e7effe;
  border-bottom: solid 1px #043899;
}

.fake-link {
  color: #C01127;
  cursor: pointer;
}

.fake-link:hover {
  text-decoration: underline;
}

footer {
  background-color: #043899;
  color: #ffffff;
}

footer a {
  color: #ccc;
}

footer a:hover {
  text-decoration: none !important;
  color: #efebe8;
}

#wm-body {
  min-height: 400px;
}

.wm-fullwidth-container {
  width: 100%;
  position: relative;
  padding: 0;
  max-height: 500px !important;
  overflow: hidden;
}

#wm-home-hero-image {
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #343331;
  height: 400px;
  background-image: url(../images/burner-hero-1.jpg);
}

#wm-home-hero-image .container {
  height: 100%;
}
